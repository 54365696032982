@import "~ngx-tabset/ngx-tabset";

/* You can add global styles to this file, and also import other style files */
body {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#stickyHeader {
  position: sticky;
  top: 0px;
  z-index: 999;
}

.bg-custom-color {
  background: #3b4550 !important;
}

.bg-lightest-gray {
  background: #f6f9fc !important;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  justify-content: center !important;
  text-align: center;
}

.form-field-err-text {
  font-size: 12px;
  color: #f5365c;
  padding-top: 3px;
  font-weight: 500;
}

.d-flex-v-center {
  display: flex;
  align-items: center;
}

.d-flex-h-center {
  display: flex;
  justify-content: center;
}

.font-12px {
  font-size: 12px;
}

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px !important;
}

.font-18px {
  font-size: 18px !important;
}

.font-22px {
  font-size: 22px;
}

.max-w-450px {
  max-width: 450px;
}

.max-w-350px {
  max-width: 350px;
}

.max-w-250px {
  max-width: 250px;
}

.min-w-80px {
  min-width: 80px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-150px {
  min-width: 150px;
}

.min-w-200px {
  min-width: 200px;
}

.min-w-250px {
  min-width: 250px;
}

.min-w-350px {
  min-width: 350px;
}

.w-500px {
  width: 500px;
}

.w-300px {
  width: 300px !important;
}

.w-90px {
  width: 90px !important;
}

.h-500px {
  height: 500px;
}

.min-h-80px {
  min-height: 80px;
}

.light-label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-35px {
  margin-top: 35px;
}

.m-minus-top-5px {
  margin-top: -5px;
}

.m-minus-top-10px {
  margin-top: -10px;
}

.m-minus-top-15px {
  margin-top: -15px !important;
}

.m-minus-top-25px {
  margin-top: -25px;
}

.m-minus-top-50px {
  margin-top: -50px;
}

.m-minus-left-10px {
  margin-left: -10px;
}

.swal2-show.swal2-loading {
  min-height: 300px !important;
}

.h-125px {
  height: 125px;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.container-m-top {
  margin-top: 35px;
}

.sw-container-class {
  background: red;

  .swal2-popup {
    padding: 0;
  }

  .swal2-popup .swal2-icon {
    margin: 0.5rem;
    min-width: 0;
  }

  .swal2-header {
    padding-left: 15px;
    flex-direction: row;
    overflow: hidden;
  }

  .swal2-title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .swal2-popup .swal2-title {
    margin: 0;
  }

  .swal2-popup .swal2-content {
    display: none;
  }

  .swal2-popup .swal2-actions {
    margin: 0 auto 1rem auto;

    .swal2-cancel {
      background-color: #c42341;
      border-color: #c42341;
      color: #ffffff;
    }

    .swal2-confirm {
      background-color: #056c68;
      border-color: #056c68;
      color: #ffffff;
      margin-left: 15px;
    }
  }
}

.line-height-2 {
  line-height: 2 !important;
}

.btn-primary {
  background-color: #FF5757 !important;
  border-color: #FF5757 !important;
}

.form-control,
.custom-file {
  height: calc(0.45em + 1.25rem + 5px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}

.btn {
  padding: 0.25rem 0.5rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  font-size: 0.75rem !important;
}

.selectr-selected {
  height: calc(0.45em + 1.25rem + 5px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}

.selectr-label,
.selectr-placeholder {
  margin-top: -4px !important;
}

.switch-text {
  color: #525f7f !important;
  font-size: 15px !important;
}

.br-14px {
  border-radius: 14px !important;
}

.padding-2-5px {
  padding: 2px 5px !important;
}

::ng-deep:root {
  /* label */
  --select2-label-text-color: #000;
  --select2-required-color: red;

  /* selection */
  --select2-selection-border-radius: 4px;
  --select2-selection-background: #fff;
  --select2-selection-disabled-background: #eee;
  --select2-selection-border-color: #aaa;
  --select2-selection-focus-border-color: #000;
  --select2-selection-text-color: #444;

  /* selection: choice item (multiple) */
  --select2-selection-choice-background: #e4e4e4;
  --select2-selection-choice-text-color: #000;
  --select2-selection-choice-border-color: #aaa;
  --select2-selection-choice-close-color: #999;
  --select2-selection-choice-hover-close-color: #333;

  /* placeholder */
  --select2-placeholder-color: #999;
  --select2-placeholder-overflow: ellipsis;

  /* reset */
  --select2-reset-color: #999;

  /* arrow */
  --select2-arrow-color: #888;

  /* dropdown panel */
  --select2-dropdown-background: #fff;
  --select2-dropdown-border-color: #aaa;

  /* search field */
  --select2-search-border-color: #aaa;
  --select2-search-background: #fff;
  --select2-search-border-radius: 0px;

  /* dropdown option */
  --select2-option-text-color: #000;
  --select2-option-disabled-text-color: #999;
  --select2-option-disabled-background: transparent;
  --select2-option-selected-text-color: #000;
  --select2-option-selected-background: #ddd;
  --select2-option-highlighted-text-color: #fff;
  --select2-option-highlighted-background: #5897fb;
  --select2-option-group-text-color: gray;
  --select2-option-group-background: transparent;

  /* hint */
  --select2-hint-text-color: #888;

  /* for Material ------------------------------------------*/
  --select2-material-underline: #ddd;
  --select2-material-underline-active: #5a419e;
  --select2-material-underline-disabled: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.26) 0,
    rgba(0, 0, 0, 0.26) 33%,
    transparent 0
  );
  --select2-material-underline-invalid: red;
  --select2-material-placeholder-color: rgba(0, 0, 0, 0.38);
  --select2-material-selection-background: #ddd;
  --select2-material-option-selected-background: rgba(0, 0, 0, 0.04);
  --select2-material-option-highlighted-text-color: #000;
  --select2-material-option-selected-text-color: #ff5722;
}

.select2-selection__rendered {
  margin-top: -13px !important;
}

.select2-selection--multiple {
  height: 30px !important;
  overflow: auto;
}

.select2-selection__placeholder {
  font-size: 13px !important;
  height: 23px !important;
}

 .my-swal {
  background: transparent !important;
}
.load{
  background:  no-repeat center url(./assets/images/final_loader.gif);
  height: 100px;
}


.safari .safari-adjust{
  margin-top: 3px !important;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

.select2-selection__choice{
  background: #fff !important;
  color:#000 !important;
}

.ng-value {
  background: #fff !important;
  color:#000 !important;
  border: 1px solid;
} 

.ng-value-icon {
  border-right: 1px solid !important;
}

button:disabled {
  cursor: not-allowed;
}  

.custom-control input:disabled ~ .custom-control-label{
  cursor: not-allowed;
}

select:disabled{
  cursor: not-allowed;
}

input:disabled{
  cursor: not-allowed;
}

textarea:disabled{
  cursor: not-allowed;
}

.navbar-brand-img1 {
  max-width: unset !important;
  max-height: unset !important;
  width: 180px;
  height: 55px;
}

.navbar-brand-img2 {
  max-width: unset !important;
  max-height: unset !important;
  width: 170px;
  height: 45px;
}